import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../../components/Layout'
import MarketingRowNav from '../../components/marketingRowNav'
import Quote from '../../components/Quote'
import ComparisonMatrix from '../../components/ComparisonMatrix'
import SideBySideImages from '../../components/SideBySideImages'
import AdditionalResources from '../../components/AdditionalResources'
import CallToAction from '../../components/CallToAction'
import Integration from '../../components/Integrations'
import FavoriteRelationships from '../../components/favoriteRelationships'

function OpenHouseBlitz({ data }) {
    const block = 'boost-vs-adwerx'
    const AdPlatformAndTypesHook = 'Ad platforms and types'
    const EnterpriseCustomizationHook = 'Enterprise customization'
    const CampaignFeaturesHook = 'Campaign features'
    const navigationRow = [
        {
            hook: AdPlatformAndTypesHook,
            title: 'Ad platforms and types',
        },
        {
            hook: EnterpriseCustomizationHook,
            title: 'Enterprise customization',
        },
        {
            hook: CampaignFeaturesHook,
            title: 'Campaign features',
        },
    ]

    return (
        <Layout
            title={
                'Adwerx vs Boost Product Comparison Based on Features, Functionality and Reviews | HomeSpotter'
            }
            description="View Adwerx and Boost product features and compare side by side"
            keywords={'Adwerx, Adwerx reviews, Adwerx vs, Adwerx alternative'}
            siteContainerClassName="boost-vs-adwerx__wrapper"
            bodyClass="boost-vs-adwerx"
        >
            <div className={`${block}__content`}>
                <div className={`${block}__header`}>
                    <div className={`${block}__content-wrap`}>
                        <div
                            className={`${block}__content-block ${block}__content-block--header`}
                        >
                            <div className={`${block}__content-row`}>
                                <div
                                    className={`${block}__content-column ${block}__content-column--left`}
                                >
                                    <h1
                                        className={`${block}__title ${block}__title--header-dark-bg`}
                                    >
                                        Adwerx Enterprise alternative
                                    </h1>
                                    <div className={`${block}__header-copy`}>
                                        <p
                                            className={`${block}__title-description`}
                                        >
                                            When it comes to providing great web
                                            ads for your real estate agents, you
                                            want a solution that saves you time
                                            and that generates tangible results.
                                            There are several approaches to
                                            choose from out there. See how Boost
                                            by HomeSpotter differs from Adwerx
                                            Enterprise so you can make the right
                                            choice for your agents and your
                                            brand.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`${block}__content-column ${block}__content-column--right`}
                                >
                                    <GatsbyImage
                                        image={
                                            data.boostvsadwerxheaderimage
                                                .childImageSharp.gatsbyImageData
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${block}__navigation-wrap`}>
                    <MarketingRowNav
                        productHint={'boost'}
                        navRow={navigationRow}
                    />
                </div>

                <div
                    className={`${block}__jump-to`}
                    id={'ad-platforms-and-types'}
                >
                    <section className={`${block}__content-wrap--section`}>
                        <div className={`${block}__content-block`}>
                            <h2 className={`${block}__title`}>
                                Ad platforms and types
                            </h2>

                            <p className={`${block}__paragraph`}>
                                Both Boost by HomeSpotter and Adwerx Enterprise
                                allow real estate organizations to automate the
                                creation of listing based ads for Facebook,
                                Instagram and high traffic websites and apps.
                                Agents benefit from having a solution in place
                                to greatly simplify ad creation, get
                                notifications and to review campaign results.
                                Brands use these platforms to increase agent
                                satisfaction and to assist in generating
                                interests in their listings.
                            </p>

                            <p className={`${block}__paragraph`}>
                                Boost by HomeSpotter was created for real estate
                                brokerages in 2016. Adwerx provides ads
                                individual agents in the real estate, mortgage,
                                insurance and financial planning industries and
                                added their brokerage automation product in
                                2018.
                            </p>

                            <p className={`${block}__paragraph`}>
                                Are you an agent considering a platform to
                                create listing ads for you or your team? View{' '}
                                <a href="#campaign-features">agent features</a>.
                            </p>

                            <p
                                className={`${block}__paragraph ${block}__paragraph--strong`}
                            >
                                Listing ad automation
                            </p>

                            <p className={`${block}__paragraph`}>
                                At its core, a listing ad automation solution
                                needs to create effective web ads and display
                                them online. Using data feeds, ads can be
                                automatically created that feature listing
                                details, the listing agent and brokerage
                                branding.
                            </p>

                            <p className={`${block}__paragraph`}>
                                Adwerx Enterprise automates ad creations for a
                                variety of franchise industries, including real
                                estate. They focus on generating a volume of ad
                                impressions by devoting a majority of campaign
                                budgets to programmatic display ad networks.
                            </p>

                            <p className={`${block}__paragraph`}>
                                Boost by HomeSpotter can also create listing ads
                                in a variety of formats based on the business
                                rules set by the broker. A higher amount of each
                                campaign’s budget is spent on social channels
                                like Facebook and Instagram, where users are
                                more likely to interact through clicks, comments
                                and shares.
                            </p>

                            <p className={`${block}__paragraph`}>
                                Two brokerage partners of HomeSpotter’s recently
                                ran a “bake off” between Boost and Adwerx. Both
                                were surprised to consistently see click through
                                rates 10x higher with Boost compared with
                                similar listing ad campaigns and budgets powered
                                by Adwerx, and chose to move forward with Boost
                                based on a head to head comparison of results.
                            </p>
                        </div>
                    </section>

                    <ComparisonMatrix
                        matrix={[
                            {
                                label: 'Automated ad types offered',
                                fields: [
                                    {
                                        label:
                                            'Just listed, open house, price reduced, and just sold',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label: 'Video ad format',
                                        boost: true,
                                        adwerx: false,
                                    },
                                    {
                                        label: 'Virtual tours',
                                        boost: true,
                                        adwerx: false,
                                    },
                                    {
                                        label: 'Virtual open house',
                                        boost: true,
                                        adwerx: false,
                                    },
                                ],
                            },
                            {
                                label: 'Manual ad types offered',
                                fields: [
                                    {
                                        label:
                                            'Just listed, open house, price reduced, and just sold',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label:
                                            'Listing video ads',
                                        boost: true,
                                        adwerx: false,
                                    },
                                    {
                                        label:
                                            'Listing video ads - Manual upload',
                                        boost: true,
                                        adwerx: false,
                                    },
                                    {
                                        label: 'Agent promo ads (aka “Sphere Ads”, “Zip Code Ads”)',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label:
                                            'Agent promo video ads',
                                        boost: true,
                                        adwerx: false,
                                    },
                                    {
                                        label:
                                            'Agent promo video ads - Manual upload',
                                        boost: true,
                                        adwerx: false,
                                    },
                                ],
                            },
                            {
                                label: 'Channels available',
                                fields: [
                                    {
                                        label: 'Facebook and Instagram',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label: 'High traffic websites (Display)',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label: 'TV commercials',
                                        boost: false,
                                        adwerx: true,
                                    },
                                    {
                                        label: 'Nextdoor',
                                        boost: true,
                                        adwerx: false,
                                    },
                                ],
                            },
                            {
                                label: 'Program customization',
                                fields: [
                                    {
                                        label: 'Default ad templates available',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label:
                                            'Facebook and Instagram ads come from the broker’s page',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label:
                                            'Custom access and permission roles for agents, admin, corporate office and more',
                                        boost: true,
                                        adwerx: false,
                                    },
                                    {
                                        label:
                                            'Campaign leads automatically added to brokerage CRM',
                                        boost: true,
                                        adwerx: false,
                                    },
                                ],
                            },
                            {
                                label: 'Campaign features',
                                fields: [
                                    {
                                        label:
                                            'Ad targeting created per property',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label:
                                            'Use first party data in targeting via custom uploads',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label:
                                            'Use first party data in targeting via website pixel',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label:
                                            'Include open house registrations in ad targeting',
                                        boost: true,
                                        adwerx: false,
                                    },
                                    {
                                        label:
                                            'Listing landing pages for lead capture provided',
                                        boost: true,
                                        adwerx: false,
                                    },
                                    {
                                        label: 'Seller targeting',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label: 'Seller reports',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label: 'Automated Seller Reporting',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label: 'Remarketing option',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label:
                                            'Option to create Lead Form campaigns for Facebook and Instagram',
                                        boost: true,
                                        adwerx: false,
                                    },
                                ],
                            },
                            {
                                label: 'Agent empowerment',
                                fields: [
                                    {
                                        label:
                                            'Open house campaigns auto run for 1-3.5 days leading to event start time',
                                        boost: true,
                                        adwerx: false,
                                    },
                                    {
                                        label:
                                            'Feeds checked for status changes every 15 minutes',
                                        boost: true,
                                        adwerx: false,
                                    },
                                    {
                                        label:
                                            'Moderate and reply to comments from email',
                                        boost: true,
                                        adwerx: false,
                                    },
                                    {
                                        label:
                                            'Agents can override location targeting settings',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label:
                                            'Agents can target multiple locations in a single ad',
                                        boost: true,
                                        adwerx: true,
                                    },
                                    {
                                        label:
                                            'Agents can select their own Facebook and Instagram pages',
                                        boost: true,
                                        adwerx: false,
                                    },
                                    {
                                        label: 'Results dashboard for agents',
                                        boost: true,
                                        adwerx: true,
                                    },
                                ],
                            },
                        ]}
                    />

                    <Quote
                        quoteText={
                            '“I recently sold a listing to someone who attended the open house because they saw my Facebook ad!”'
                        }
                        attribution={
                            '— Gayle Satcher, Realtor John R. Wood Properties'
                        }
                        side={'right'}
                        img={data.gayle}
                    />

                    <SideBySideImages
                        imageLeft={data.socialAd}
                        imageLeftLabel={'Boost by HomeSpotter'}
                        imageRight={data.adwerxExample}
                        imageRightLabel={'Adwerx Enterprise'}
                    />

                    <Quote
                        quoteText={
                            "“We found the launch of our partnership with HomeSpotter to be one of the easiest we've had in a while. Their customer service team is honest, friendly and most importantly flexible.”"
                        }
                        attribution={
                            '— Kathryn Redican Vice President of Operations, BHHS New England '
                        }
                        img={data.kathryn}
                        modifier={'no-bottom-pad'}
                    />

                    <div
                        className={`${block}__jump-to`}
                        id={'enterprise-customization'}
                    >
                        <section className={`${block}__content-wrap--section`}>
                            <div className={`${block}__content-block`}>
                                <h2 className={`${block}__title`}>
                                    Enterprise customization
                                </h2>

                                <p className={`${block}__paragraph`}>
                                    Adwerx Enterprise and Boost by HomeSpotter
                                    take different approaches when it comes to
                                    program customization. For clients that
                                    don’t require a high level of
                                    personalization, Adwerx Enterprise can offer
                                    a solution right off the shelf.
                                </p>

                                <p className={`${block}__paragraph`}>
                                    In contrast, Boost by HomeSpotter offers
                                    flexibility to brokers that want to
                                    configure their experience. Brokerages can
                                    choose from a host of options during program
                                    set up and HomeSpotter has a history of
                                    honoring out of the box requests to meet
                                    customer needs.
                                </p>
                            </div>
                        </section>
                    </div>

                    <Quote
                        quoteText={
                            '“We chose Boost to handle our digital ads because it offered more ways to impress sellers and because of our previous relationship with HomeSpotter. We keep asking them for personal touches that are right for our brand and they keep on delivering.”'
                        }
                        attribution={
                            '— Jim Newell Vice President of Marketing Carpenter Realtors'
                        }
                        side={'right'}
                        img={data.jim}
                        modifier="no-bottom-pad"
                    />

                    <div
                        className={`${block}__jump-to`}
                        id={'campaign-features'}
                    >
                        <section className={`${block}__content-wrap--section`}>
                            <div className={`${block}__content-block`}>
                                <h2 className={`${block}__title`}>
                                    Campaign features and agent empowerment
                                </h2>

                                <p className={`${block}__paragraph`}>
                                    A successful automation program will go
                                    beyond simply publishing ads to include
                                    extra tools that agents need to succeed. By
                                    providing additional features the service
                                    provider can help the brokerage avoid
                                    additional development work and support.
                                </p>

                                <p className={`${block}__paragraph`}>
                                    Both tools offer agents the opportunity to
                                    engage their seller along the way, which is
                                    an important point of value for agents.
                                    Boost by HomeSpotter includes a variety of
                                    notifications to keep agents tuned into
                                    campaigns and engaged, which are
                                    configurable by the brokerage
                                </p>
                            </div>
                        </section>
                    </div>

                    {/*
                            <Quote
                                quoteText={"“<<Customer quote on responding quickly to hot leads from comment notification>>”"}
                                attribution={"— Kathryn Redican Vice President of Operations, BHHS New England "}
                                side={"left"}
                                img={data.kathryn}
                            />
                        */}

                    <div className={`${block}__daily-summary`}>
                        <section className={`${block}__content-wrap--section`}>
                            <div className="boost-vs-adwerx__content-block">
                                <div
                                    className={`${block}__daily-summary-image`}
                                >
                                    <GatsbyImage
                                        image={
                                            data.dailySummary.childImageSharp
                                                .gatsbyImageData
                                        }
                                    />
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className={`${block}__daily-summary`}>
                        <section className={`${block}__content-wrap--section`}>
                            <div className={`${block}__content-block`}>
                                <h2 className={`${block}__title`}>Summary</h2>

                                <p className={`${block}__paragraph`}>
                                    Listing ad automation can be a powerful way
                                    for brands to add value to agents and to
                                    help them succeed in digital advertising.
                                    Boost by HomeSpotter is a highly
                                    configurable solution for brokerages that
                                    want to maintain control over their brand
                                    and who need a product that’s tailored to
                                    their unique structure.
                                </p>
                            </div>
                        </section>
                    </div>

                    <AdditionalResources
                        title={'Additional Resources'}
                        subtitle={'Boost by HomeSpotter case studies'}
                        links={[
                            {
                                link:
                                    'https://offers.homespotter.com/parks-case-study-pdf-offer/',
                                text:
                                    'How PARKS Realty used Boost to create 800 ad campaigns in 60 days',
                            },
                            {
                                link:
                                    'https://offers.homespotter.com/carpenter-case-study-pdf/',
                                text:
                                    'Carpenter gains an edge with faster listing promotion',
                            },
                            {
                                link:
                                    'https://offers.homespotter.com/parkco-case-study-pdf/',
                                text:
                                    'Park Co dominates their local market with superior data',
                            },
                        ]}
                    />

                    <CallToAction
                        modifier={'no-top-margin'}
                        content={
                            'Ready to elevate your advertising experience?'
                        }
                        theme={'boost'}
                        action={{
                            text: 'View a demo of Boost',
                            component: 'ViewDemo',
                            props: '',
                        }}
                    />

                    <div className={`${block}__daily-summary`}>
                        <section className={`${block}__content-wrap--section`}>
                            <div
                                className={`${block}__content-block ${block}__content-block--no-horizontal-pad`}
                            >
                                <Integration
                                    modifier={'less-padding-bottom'}
                                    followUp={
                                        "Already integrated with the industry's top third-party vendors."
                                    }
                                    hook={'Integrations in a cinch'}
                                    logos={[
                                        {
                                            src: data.boomtown,
                                            name: 'boomtown',
                                        },
                                        {
                                            src: data.bostonlogic,
                                            name: 'bostonlogic',
                                        },
                                        {
                                            src: data.leadtrax,
                                            name: 'leadtrax',
                                        },
                                        {
                                            src: data.idcglobal,
                                            name: 'idcglobal',
                                        },
                                        {
                                            src: data.buyside,
                                            name: 'buyside',
                                        },
                                        {
                                            src: data.deltamediagroup,
                                            name: 'deltamediagroup',
                                        },
                                        {
                                            src: data.moxiworks,
                                            name: 'moxiworks',
                                        },
                                        {
                                            src: data.tribus,
                                            name: 'tribus',
                                        },
                                        {
                                            src: data.realestatewebmasters,
                                            name: 'realestatewebmasters',
                                        },
                                        {
                                            src: data.reliance,
                                            name: 'reliance',
                                        },
                                        {
                                            src: data.red,
                                            name: 'red',
                                        },
                                    ]}
                                />
                            </div>
                        </section>
                    </div>

                    <FavoriteRelationships
                        customers={[
                            'BHGRE',
                            'BHHSC',
                            'BHHSG',
                            'Carpenter',
                            'HPW',
                            'Cresev',
                            'Elliman',
                            'Intercoastal',
                            'JohnLScott',
                            'Parkco',
                            'Parks',
                        ]}
                    />
                </div>
            </div>
        </Layout>
    )
}

const AdwerxAlternativeRender = props => (
    <StaticQuery
        query={graphql`
            {
                boostvsadwerxheaderimage: file(
                    relativePath: {
                        eq: "boost-vs-adwerx-header-illustration.png"
                    }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 520
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                jim: file(relativePath: { eq: "jim-newell.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                bostonlogic: file(relativePath: { eq: "bostonlogic.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                boomtown: file(relativePath: { eq: "boomtown.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                leadtrax: file(relativePath: { eq: "leadtrax.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                idcglobal: file(relativePath: { eq: "idcglobal.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                buyside: file(relativePath: { eq: "buyside.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                deltamediagroup: file(
                    relativePath: { eq: "deltamediagroup.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                moxiworks: file(relativePath: { eq: "moxiworks.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                tribus: file(relativePath: { eq: "tribus.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                realestatewebmasters: file(
                    relativePath: { eq: "realestatewebmasters.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                reliance: file(relativePath: { eq: "reliance.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                red: file(relativePath: { eq: "red.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                kathryn: file(relativePath: { eq: "kathryn.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                dailySummary: file(relativePath: { eq: "dailySummary.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 615
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                adwerxExample: file(
                    relativePath: { eq: "adwerx-example.jpg" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 325
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                socialAd: file(relativePath: { eq: "social-ad-1.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 275
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                gayle: file(relativePath: { eq: "gayle.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                andrew: file(relativePath: { eq: "andrew-ratner.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 200
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                edinaopenhouse: file(
                    relativePath: { eq: "openhouseblitz/edina-open-house.jpg" }
                ) {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                }
                cta: file(
                    relativePath: { eq: "case-study/blitz-case-study-bg.jpg" }
                ) {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                }
                ohLeads: file(
                    relativePath: { eq: "openhouseblitz/hero-cta-oh-leads.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                }
                ctaChecklist: file(
                    relativePath: {
                        eq: "openhouseblitz/hero-cta-checklist.png"
                    }
                ) {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                }
                hero: file(relativePath: { eq: "openhouseblitz/hero.png" }) {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                    }
                }
                ch1_hero: file(
                    relativePath: { eq: "openhouseblitz/ch1-hero.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 493
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                ch2_hero: file(
                    relativePath: { eq: "openhouseblitz/ch2-hero.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 493
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                ch3_hero: file(
                    relativePath: { eq: "openhouseblitz/ch3-hero.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 493
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                ch4_hero: file(
                    relativePath: { eq: "openhouseblitz/ch4-hero.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 480
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                ch5_hero: file(
                    relativePath: { eq: "openhouseblitz/ch5-hero.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 447
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                ftb_open_house: file(
                    relativePath: { eq: "openhouseblitz/ftb-open-house.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 302
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                fourGroupsOpenHouses: file(
                    relativePath: {
                        eq: "openhouseblitz/four-groups-agents-open-houses.jpg"
                    }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 302
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                ftb_manual_ads: file(
                    relativePath: { eq: "openhouseblitz/ftb-manual-ads.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 270
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                reece_open_house_celebration: file(
                    relativePath: {
                        eq: "openhouseblitz/reece-open-house-celebration.png"
                    }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 780
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                mega_alexa: file(
                    relativePath: { eq: "openhouseblitz/mega-alexa.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 320
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
                instagram_post: file(
                    relativePath: { eq: "openhouseblitz/instagram-post.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 780
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
            }
        `}
        render={data => <OpenHouseBlitz data={data} {...props} />}
    />
)

export default AdwerxAlternativeRender
