import React from 'react'

export default class MarketingRowNav extends React.Component {
    state = { headerIsActive: false }

    constructor(props) {
        super(props)
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll)

        const { navRow } = this.props

        this.navRow = navRow.map(el => {
            el.hook = this.formatHook(el.hook)
            el.el = document.getElementById(el.hook)
            el.target = document.querySelector('a[href="#' + el.hook + '"]')
            return el
        })
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        var el = document.getElementById('hooks')
        var container = document.getElementById('hooks-container')

        let elHeight = el.getBoundingClientRect().top
        let containerHeight = container.getBoundingClientRect().top

        let previousElements = []
        this.navRow.map(el => {
            let target = document.querySelector('a[href="#' + el.hook + '"]')

            if (el.el.getBoundingClientRect().top <= 1) {
                if (previousElements.length) {
                    previousElements.map(el => {
                        el.classList.remove('active')
                        target.classList.remove('active')
                    })
                }

                el.el.classList.add('active')
                target.classList.add('active')

                previousElements.push(target)
            } else {
                el.el.classList.remove('active')
                target.classList.remove('active')
            }

            return el.el.getBoundingClientRect().top
        })

        if (containerHeight >= 0) {
            this.setState({
                headerIsActive: false,
            })
        } else if (elHeight <= 0) {
            this.setState({
                headerIsActive: true,
            })
        }
    }

    formatHook(hook) {
        return hook.toLowerCase().replace(/\s+/g, '-')
    }

    render() {
        const { navRow } = this.props
        return (
            <div id="hooks-container" className="row-container">
                <div
                    id="hooks"
                    className={`hook-row ${
                        this.state.headerIsActive ? 'sticky' : ''
                    }`}
                >
                    <div className="hook-links">
                        {navRow.map(row => (
                            <a
                                href={`#${this.formatHook(row.hook)}`}
                                className={`hook ${this.props.productHint}`}
                                key={row.hook}
                            >
                                {row.title}
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}
