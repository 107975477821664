import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

const Integration = ({ hook, followUp, logos, productHint, block='integration', modifier='default'}) => {
    const lgGrid = logos.length > 11
    return (
        <div className={`${block} ${block}--${modifier}`}>
            <div id="integrations" className={`${block}__container ${productHint} col-12`}>
                <h1>{hook}</h1>
                <div className={`${block}__dots`}>
                    <div className="dot rotate-78" />
                    <div className="dot rotate-134" />
                    <div className="dot rotate-178" />
                    <div className="dot rotate-129" />
                </div>
                <div className={`${block}__content`}>{followUp}</div>
                <div className={`${block}__grid ${lgGrid ? 'size-lg' : ''}`}>
                    {logos.map((img, i) => {
                        return (
                            <div key={`logo-${i}`} className="img-wrapper">
                                <GatsbyImage image={img.src.childImageSharp.gatsbyImageData} />
                            </div>
                        );
                    })}
                </div>
                <Link to="/integrations">
                    Learn more about our integrations
                    <span className="arrow">➜</span>
                </Link>
            </div>
        </div>
    );
};

export default Integration
