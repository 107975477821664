import React from 'react'
import ViewDemo from './ViewDemo'

export default function CallToAction({ action, theme, content, modifier = 'default' }) {
    let Component;

    switch (action.component) {
        case 'ViewDemo':
            Component = ViewDemo;
            break;
        default:
            Component = action.component;
            break
    }

    return (
        <div className={`cta cta--${modifier}`}>
            <div className={`cta-container col-12 ${theme}`}>
                <div className="cta-content">{content}</div>
                <div className="button-container">
                    <Component {...action.props}>
                        <button
                            className={`button button-secondary ${theme} __cta_tag`}
                        >
                            {action.text}
                        </button>
                    </Component>
                </div>
            </div>
        </div>
    )
}
