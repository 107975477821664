import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

export default class Quote extends React.Component {

    render() {
        const {quoteText, attribution, img, side = 'left', block = "quote", modifier = "default"} = this.props;
        return (
            <div className={`${block} ${block}--${modifier} ${block}--${side}`}>
                <div className={`${block}__image`}>
                    <GatsbyImage image={img.childImageSharp.gatsbyImageData} />
                </div>

                <div className={`${block}__quote-wrap`}>
                    <div className={`${block}__quote`}>{quoteText}</div>
                    <div className={`${block}__attribution`}>{attribution}</div>
                </div>

            </div>
        );
    }
}