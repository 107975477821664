import React from 'react'

export default class ComparisonMatrix extends React.Component {
    getHeader(item, block) {
        return (
            <React.Fragment>
                <div
                    className={`${block}__header__label ${block}__header__label--main`}
                >
                    {item.label}
                </div>
                <div className={`${block}__header__label`}>
                    <span className={`${block}__header__mobile-label`}>
                        Boost
                    </span>{' '}
                    by HomeSpotter
                </div>
                <div className={`${block}__header__label`}>
                    <span className={`${block}__header__mobile-label`}>
                        Adwerx
                    </span>{' '}
                    Enterprise
                </div>
            </React.Fragment>
        )
    }

    getBody(item, block) {
        let output

        output = item.fields.map((item, key) => {
            return (
                <div className={`${block}__row`}>
                    {this.getRow(item, block)}
                </div>
            )
        })

        return output
    }

    getRow(item, block) {
        const boostClass =
            item.boost === true ? `${block}__row__column--active` : ''
        const adwerxClass =
            item.adwerx === true ? `${block}__row__column--active` : ''

        return (
            <React.Fragment>
                <div
                    className={`${block}__row__column ${block}__row__column--label`}
                >
                    {item.label}
                </div>
                <div
                    className={`${block}__row__column ${block}__row__column--boost ${boostClass}`}
                >
                    {item.boost}
                </div>
                <div
                    className={`${block}__row__column ${block}__row__column--adwerx ${adwerxClass}`}
                >
                    {item.adwerx}
                </div>
            </React.Fragment>
        )
    }

    build(matrix, block) {
        let output

        output = matrix.map((item, key) => {
            return (
                <div className={`${block}__body`}>
                    <div className={`${block}__header`}>
                        {this.getHeader(item, block)}
                    </div>
                    {this.getBody(item, block)}
                </div>
            )
        })

        return output
    }

    render() {
        const {
            matrix,
            block = 'comparison',
            modifier = 'default',
        } = this.props

        return (
            <div className={`${block} ${block}--${modifier}`}>
                <div className={`${block}__wrap`}>
                    {this.build(matrix, block)}
                </div>
            </div>
        )
    }
}
