import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

export default class SideBySideImages extends React.Component {

    render() {
        const {imageLeft, imageLeftLabel, imageRight, imageRightLabel, block = "side-by-side", modifier = "default"} = this.props;
        return (
            <div className={`${block} ${block}--${modifier}`}>
                <div className={`${block}__wrap`}>
                    <div className={`${block}__image ${block}__image--left`}>
                        <GatsbyImage image={imageLeft.childImageSharp.gatsbyImageData} />
                        <div className={`${block}__image-label ${block}__image-label--left`}>{imageLeftLabel}</div>
                    </div>

                    <div className={`${block}__image ${block}__image--right`}>
                        <GatsbyImage image={imageRight.childImageSharp.gatsbyImageData} />
                        <div className={`${block}__image-label ${block}__image-label--right`}>{imageRightLabel}</div>
                    </div>
                </div>
            </div>
        );
    }
}