import React from 'react'

export default class AdditionalResources extends React.Component {
    render(){
        const {title, subtitle, links, block = "additional-resources", modifier = "default"} = this.props;

        return(
            <div className={`${block} ${block}--${modifier}`}>
                <div className={`${block}__wrap`}>
                    <div className={`${block}__title`}>{title}</div>
                    <div className={`${block}__row`}>
                        <div className={`${block}__subtitle`}>{subtitle}</div>
                        <ul className={`${block}__links`}>
                            {links.map((item, key) => (
                                <li className={`${block}__link-li`}><a className={`${block}__link`} href={item.link}>{item.text}</a></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}